<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        :queryInfo.sync="queryInfo"
        :tool-list="['department','college','keyword']"
        style="display: inline-block;"
        @on-search="renderTable(1)"/>
      <div class="header-button fr">
        <el-button size="mini" type="primary" @click="showDialog()">添加角色</el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column prop="roleName" label="角色" width="300" />
      <el-table-column label="部门/院系" width="150">
        <template v-slot="{row}">
          {{ row.deptName ? row.deptName : (row.collegeName ? row.collegeName : '') }}
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="250" />
      <el-table-column prop="createTime" width="170" label="创建时间" align="center" />
      <el-table-column label="操作" width="220" fixed="right">
        <template v-slot="{row}">
          <el-button type="primary" plain size="mini"
                     @click="showSubDialog('visibleRoleTreeDialog','roleTreeDialogRef',row.id,`-${row.roleName}`)">分配菜单
          </el-button>
          <el-button type="primary" size="mini" @click="showDialog(row.id,`-${row.roleName}`)">编辑</el-button>
          <!-- id只有两位数的为系统基础角色,不可删除,可以编辑 -->
          <el-button size="mini" v-if="row.id.length>2 && row.roleName !== '超级管理员'" type="danger"
                     @click="del(deleteRoleById, row.id, `角色-${row.roleName}`)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <add-role ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
              :department-list="departmentList"
              @on-close="renderTable" />
    <set-role-menu ref="roleTreeDialogRef" :visible.sync="visibleRoleTreeDialog" v-if="visibleRoleTreeDialog"
                   @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getRoleList, deleteRoleById } from '@/api/system/role-api'
import addRole from './dialog/addRole.vue'
import setRoleMenu from './dialog/setRoleMenu.vue'
import tableView from '@/vue/mixins/table-view'

export default {
  name: 'role',
  components: {
    addRole,
    setRoleMenu
  },
  mixins: [tableView],
  data () {
    return {
      visibleRoleTreeDialog: false,
      departmentList: [],
      queryInfo: {
        collegeId: null,
        deptId: null
      }
    }
  },
  mounted () {
    this.getDepartmentList()
  },
  methods: {
    deleteRoleById, // 删除api
    // 获取角色列表
    renderTable (pageNum) {
      this.getTableData(getRoleList, pageNum)
    },
    // 获取部门
    getDepartmentList () {
      this.$http.getDepartmentListQuery({}).then(res => {
        this.departmentList = res.data
      })
    }
  }
}
</script>
