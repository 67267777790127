<template>
  <el-dialog
    class="role-tree-dialog"
    :title="`分配角色菜单-${title}`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="close"
    width="540px"
  >
    <el-input
      class="mb-1"
      size="small"
      placeholder="输入关键字进行过滤"
      v-model="keyword">
    </el-input>
    <el-scrollbar style="height: calc(100% - 29px);border: 1px solid #eee; border-radius: 4px;">
      <el-tree
        ref="treeRef"
        :data="menuList"
        show-checkbox
        default-expand-all
        node-key="id"
        check-strictly
        default-expanded-all
        :default-checked-keys="roleMenuList"
        check-on-click-node
        :props="defaultProps"
        :filter-node-method="filterNode"
        @check="handleCheckNode" />
    </el-scrollbar>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="addRoleMenu">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getMenuList } from '@/api/system/menu-api'
import { getRoleMenuById, setRoleMenu } from '@/api/system/role-api'
import dialog from '@/vue/mixins/dialog'
import _ from 'lodash'

export default {
  mixins: [dialog],
  data() {
    return {
      keyword: '', // 过滤 树
      menuList: [],
      roleMenuList: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      }
    }
  },
  watch: {
    keyword(val) {
      this.$refs.treeRef.filter(val)
    }
  },
  mounted() {
    this.getMenus()
    this.$nextTick(() => {
      if (this.id) {
        this.getRoleMenu(this.id)
      }
    })
  },
  methods: {
    /* 获取菜单树 */
    getMenus() {
      getMenuList().then(res => {
        this.menuList = res.data
      })
    },
    /* 获取当前角色菜单 */
    getRoleMenu(id) {
      getRoleMenuById(id).then(res => {
        this.roleMenuList = res.data
      })
    },
    /* 控制选中
      *  父级选中/取消,所有子级以及子级的子级都选中/取消
      *  子级选中,其所有直属父级都被选中
      *  取消子级不会影响父级。
      * */
    async handleCheckNode(val) {
      const _node = this.$refs.treeRef.getNode(val.id)
      if (!_node) return
      // 子级被选中，会将父级和祖父级也选中，否则不选中。
      const _parentId = _.get(_node, 'data.parentId')
      const _isChecked = _.get(_node, 'parent.checked')
      if (_parentId !== '0' && _node.checked && !_isChecked) {
        this.handleParentNode(val.id)
      }
      const _nodeChild = _.get(_node, 'data.children')
      if (_nodeChild?.length) {
        for (const item of _node.data.children) {
          // 设置子级 选中/取消选中
          this.$refs.treeRef.setChecked(item.id, _node.checked)
          // 递归设置孙子级的选中/取消
          const _promises = []
          const _itemChilds = _.get(item, 'children')
          if (_itemChilds?.length) {
            _promises.push(() => this.handleCheckNode(item))
          }
          await Promise.all(_promises.map(func => func()))
        }
      }
    },
    handleParentNode(id) {
      const _node = this.$refs.treeRef.getNode(id)
      if (!_node) return
      if (_node.data.parentId) {
        this.$refs.treeRef.setChecked(_node.data.parentId, true)
        if (_node.parent.data.parentId) {
          this.handleParentNode(_node.parent.data.id)
        }
      }
    },
    // 搜索 过滤
    filterNode(value, data) {
      if (!value) return true
      return data.title.indexOf(value) !== -1
    },
    /* 添加角色菜单 */
    addRoleMenu() {
      const menuIdList = this.$refs.treeRef.getCheckedKeys()
      const roleMenuInfo = {
        menuIdList,
        roleId: this.id
      }
      setRoleMenu(roleMenuInfo).then(() => {
        this.$message.success('添加成功')
        this.closed()
      })
    }
  }
}
</script>

<style lang="scss">
.role-tree-dialog {
  .el-dialog__body {
    min-height: 300px;
    height: 60vh;
    overflow: hidden;
    padding: 20px 20px 0;
  }
}
</style>
