<template>
  <el-dialog
    class="addRole-dialog"
    :title="`${preTitle}角色`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="cancel"
    width="420px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model.trim="formData.roleName" />
      </el-form-item>
      <!--  互斥 选了部门禁选 院系 -->
      <el-form-item label="部门">
        <tool-tips-group is-flex tips-content="分配了部门后，不能分配院系。需要分配院系时，请先把本选项清空。">
          <el-select
            v-model="formData.deptId"
            filterable
            clearable
            :disabled="!!formData.collegeId"
            @change="handleOnlyVal($event,'collegeId')"
          >
            <el-option
              v-for="{deptName, id} in departmentList"
              :label="deptName"
              :value="id"
              :key="id" />
          </el-select>
        </tool-tips-group>
      </el-form-item>
      <!--  互斥 选了collegeId禁选 deptId -->
      <el-form-item label="院系">
        <tool-tips-group is-flex tips-content="分配了院系后，不能分配部门。需要分配部门时，请先把本选项清空。">
          <el-select
            v-model="formData.collegeId"
            filterable
            clearable
            :disabled="!!formData.deptId"
            @change="handleOnlyVal($event,'deptId')"
          >
            <el-option
              v-for="{collegeName, id} in collegeList"
              :label="collegeName"
              :value="id"
              :key="id" />
          </el-select>
        </tool-tips-group>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addRole, getRoleInfoById } from '@/api/system/role-api'
import dialog from '@/vue/mixins/dialog'

export default {
  mixins: [dialog],
  props: {
    addData: {
      type: Object
    },
    departmentList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      collegeList: [],
      formData: {
        id: '',
        roleName: '',
        deptId: '',
        collegeId: '',
        remarks: ''
      },
      appServiceList: [],
      rules: {
        roleName: [
          {
            required: true,
            message: '请输入角色名',
            trigger: 'blur'
          },
          {
            min: 2,
            max: 20,
            message: '长度在 2 到 20 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    this.getCollegeList()
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getRoleInfoById)
      }
    })
  },
  methods: {
    // 互斥赋值
    handleOnlyVal (val, key) {
      if (val) {
        this.formData[key] = ''
      }
    },
    // 获取院系
    getCollegeList () {
      this.$http.getCollegeListQuery({}).then((res) => {
        this.collegeList = res.data
      })
    },
    async handleSave () {
      await this.save('角色', addRole)
    }
  }
}
</script>
